<template>
  <div data-layout="auth/guest" class="bg-forgd-neutral-400 min-h-screen font-normal text-forgd-primary-900 pt-4 bg-[url(/auth/prelogin.svg)]">
    <div class="max-w-forgd mx-auto px-6 flex flex-col items-center justify-center py-14">
      <div class="mb-8">
        <CoreLogo dark />
      </div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>
